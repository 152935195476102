import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import Layout from 'components/layout'
import Intro from 'components/intro'
import CopyBlock from 'components/copyBlock'
import CopySplit from 'components/copySplit'
import { default as Link } from 'components/pageTransitionLink'
import { graphql } from 'gatsby'

const PrivacyPage = ({ data, location }) => (
  <Layout location={location}>
    <Helmet>
      <title>{data.privacyJson.title}</title>
      <meta name="description" content={data.privacyJson.description} />
    </Helmet>
    <Intro topMargin headingLevel="h1" intro={data.privacyJson.intro} />
    <CopyBlock topMargin>
      <CopySplit>
        <h3>Personal data we collect</h3>
        <div>
          <p>
            GDPR covers the protection of personal data. Personal data is defined as any data that identifies an
            individual, either directly or indirectly. Personal data includes but is not limited to:
          </p>
          <ul>
            <li>Name</li>
            <li>Address</li>
            <li>Phone number</li>
            <li>Email address</li>
          </ul>
        </div>
      </CopySplit>
      <CopySplit>
        <h3>Why we collect data</h3>
        <div>
          <h4>PCW Solutions collect personal data to assist with the following:</h4>
          <ul>
            <li>Manage Contract or Framework Agreements</li>
            <li>Provide sales and marketing material</li>
            <li>Contact you in relation to services offered or supplied</li>
            <li>Manage ongoing relationship as either client or contractor</li>
          </ul>
          <h4>The legal basis on which we hold this information is:</h4>
          <ul>
            <li>Legitimate Interest - We believe that the information we supply will be of a commercial interest</li>
            <li>Contract - We need to communicate with you to fulfil contractual obligations</li>
            <li>
              Consent - Specific consent has been provided by yourselves to process your personal data. It is your legal
              right to withdraw this consent at any time
            </li>
          </ul>
        </div>
      </CopySplit>
      <CopySplit>
        <h3>How long we retain your data</h3>
        <div>
          <p>
            Personal data of a customer is held for seven years after you cease to be a customer. Personal Data held for
            marketing purposes is held for a period of three years.
          </p>
          <p>
            Personal data may be held paper based and/or electronically. Any information held in paper form is protected
            with various security measures such as storage in a locked facility. Electronic storage is protected by
            suitable software which is reviewed periodically.
          </p>
        </div>
      </CopySplit>
      <CopySplit>
        <h3>Who we share your data with</h3>
        <p>
          PCW Solutions will not share your Personal data with a third party for marketing purposes. We may however
          share Personal data with debt collection agents or our solicitors for the purpose of recovering funds owed.
        </p>
      </CopySplit>
      <CopySplit>
        <h3>What rights you have over your data</h3>
        <div>
          <p>
            You have the right to be informed of any Personal data held, this request must be in writing and all
            requests will be responded to within 30 days. You also have the right to restrict processing of this data or
            to have errors rectified.
          </p>
          <p>Should you have any queries or concerns about data held by PCW Solutions please contact:</p>
          <p>
            Data Protection Officer: Alex Hill
            <br />
            PCW Solutions
            <br />
            6th Floor
            <br />
            International House
            <br />
            223 Regent Street
            <br />
            London W1B 2QD
          </p>
          <p>
            Or email <a href="mailto:info@pcwsolutions.co.uk">info@pcwsolutions.co.uk</a>
          </p>
        </div>
      </CopySplit>
      <CopySplit>
        <h3>Independent advice on data protection and privacy</h3>
        <div>
          <p>
            Contact the Information Commissioner’s Office (ICO) for independent advice about data protection, privacy
            and data-sharing issues. You have the right to lodge a complaint with the ICO about any of our information
            rights practices.
          </p>
          <p>
            Information Commissioner’s Office <br />
            Wycliffe House
            <br />
            Water Lane
            <br />
            Wilmslow
            <br />
            Cheshire
            <br />
            SK9 5AF
          </p>
        </div>
      </CopySplit>
      <CopySplit>
        <h3>Cookies</h3>
        <div>
          <p>
            Our website uses cookies, as almost all websites do, to help provide you with the best experience we can.
            Cookies are small text files that are placed on your computer or mobile phone when you browse websites.
          </p>
          <p>
            See our <Link to="/cookies-policy">Cookies Policy</Link> for more information.
          </p>
        </div>
      </CopySplit>
    </CopyBlock>
  </Layout>
)

PrivacyPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
}

export default PrivacyPage

export const query = graphql`
  query PrivacyPageQuery {
    privacyJson {
      title
      description
      intro {
        heading
        copy
      }
    }
  }
`
